import { render, staticRenderFns } from "./AlphaInvestorFilterCRUD.vue?vue&type=template&id=7b4e7cb6&"
import script from "./AlphaInvestorFilterCRUD.vue?vue&type=script&lang=js&"
export * from "./AlphaInvestorFilterCRUD.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b4e7cb6')) {
      api.createRecord('7b4e7cb6', component.options)
    } else {
      api.reload('7b4e7cb6', component.options)
    }
    module.hot.accept("./AlphaInvestorFilterCRUD.vue?vue&type=template&id=7b4e7cb6&", function () {
      api.rerender('7b4e7cb6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dxa/filterCrud/AlphaInvestorFilterCRUD.vue"
export default component.exports