import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.Loading
    ? _c(
        VContainer,
        { attrs: { id: "users-table", fluid: "", tag: "section" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.get_users_by_page($event)
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _vm.filter
                ? _c(
                    VRow,
                    { staticClass: "mt-6", attrs: { justify: "center" } },
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              dense: "",
                              outlined: "",
                              label: _vm.$t("name") + "/" + _vm.$t("email"),
                            },
                            model: {
                              value: _vm.name_filter,
                              callback: function ($$v) {
                                _vm.name_filter = $$v
                              },
                              expression: "name_filter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("order_by"),
                              items: _vm.FilterOrderOptions,
                              "item-text": "text",
                              "item-value": "index",
                            },
                            model: {
                              value: _vm.filter_order_selected,
                              callback: function ($$v) {
                                _vm.filter_order_selected = $$v
                              },
                              expression: "filter_order_selected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("type"),
                              items: _vm.filter_order_type_options,
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _vm.filter_order_type_selected,
                              callback: function ($$v) {
                                _vm.filter_order_type_selected = $$v
                              },
                              expression: "filter_order_type_selected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("selected_portfolio"),
                              items: _vm.portfolioOptions,
                              "item-text": "number",
                              "item-value": "id",
                            },
                            model: {
                              value: _vm.portfolioSelected,
                              callback: function ($$v) {
                                _vm.portfolioSelected = $$v
                              },
                              expression: "portfolioSelected",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("created_user_exists"),
                              items: _vm.filter_options,
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _vm.createdUserFilter,
                              callback: function ($$v) {
                                _vm.createdUserFilter = $$v
                              },
                              expression: "createdUserFilter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("completed_second_register"),
                              items: _vm.filter_options,
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _vm.secondRegisterFilter,
                              callback: function ($$v) {
                                _vm.secondRegisterFilter = $$v
                              },
                              expression: "secondRegisterFilter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(VSelect, {
                            attrs: {
                              outlined: "",
                              dense: "",
                              label: _vm.$t("request_pending_approval"),
                              items: _vm.filter_options,
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _vm.requestPendingFilter,
                              callback: function ($$v) {
                                _vm.requestPendingFilter = $$v
                              },
                              expression: "requestPendingFilter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        {
                          staticClass: "text-center",
                          attrs: { cols: "12", md: "3" },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "mx-3 dxa-btn-primary",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.$t("apply")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("name"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("email"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("invest_value"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("selected_portfolio"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("created_user_exists"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("last_login"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("completed_second_register"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("request_pending_approval"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("options"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.Users, function (user, index) {
                return _c("tr", { key: index }, [
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.gs.show_full_name(user))),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(user.Email)),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.gs.format_to_currency(user.InvestValue)) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          user.SelectedPortfolioId
                            ? user.SelectedPortfolio.Number
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          user.CreatedUser ? _vm.$t("yes") : _vm.$t("no")
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          user.LastLoginDate
                            ? _vm.gs.convert_date(user.LastLoginDate, true)
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          user.CreatedUser
                            ? user.CompletedSecondRegister
                              ? _vm.$t("yes")
                              : _vm.$t("no")
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          user.CreatedUser
                            ? user.RequestPending
                              ? _vm.$t("yes")
                              : _vm.$t("no")
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-center" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 primary",
                          attrs: { "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.add_signature_notification(user)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-cash-plus"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1 secondary",
                          attrs: { "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.edit_user(user)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-pencil"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "px-2 ml-1",
                          attrs: { color: "red", "min-width": "0", small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.open_delete_dialog(user)
                            },
                          },
                        },
                        [
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("mdi-trash-can"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(VPagination, {
            attrs: { color: "primary", length: _vm.Pager.TotalPages },
            on: { input: _vm.changePage },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c("AddSignatureNotification", {
                    attrs: { User: _vm.user },
                    on: {
                      close: _vm.reset_request_dialog,
                      update: _vm.reset_request_dialog,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: {
                      obj: _vm.delete_obj,
                      name: _vm.delete_obj.Name + " " + _vm.delete_obj.LastName,
                    },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.delete_user,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }