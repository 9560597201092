import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "users-table", fluid: "", tag: "section" } },
    [
      _c(
        "base-material-card",
        {
          staticClass: "px-5 py-3 mb-5",
          attrs: { icon: "mdi-account", inline: "", color: "primary" },
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function () {
                return [
                  _c(VIcon, { attrs: { large: "" } }, [
                    _vm._v("mdi-account"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "after-heading",
              fn: function () {
                return [
                  _c("h1", { staticClass: "h5" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$tc("alpha_investor", 2)))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VBtn,
            {
              staticStyle: { right: "65px" },
              attrs: {
                color: "primary",
                rounded: "",
                loading: _vm.reportDownloading,
                absolute: "",
                small: "",
                fab: "",
                top: "",
                right: "",
              },
              on: { click: _vm.download_allocations_report },
            },
            [_c(VIcon, [_vm._v("mdi-download")])],
            1
          ),
          _c("AlphaInvestorFilterCRUD", {
            attrs: {
              Users: _vm.users,
              Pager: _vm.pager,
              Loading: _vm.loading,
              FilterOrderOptions: _vm.filter_order_options,
            },
            on: { load_more: _vm.get_users_by_page },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }